<template>
    <!-- Page: pages/my-notifications -->
    <br/>

    <a href="/my-notifications" ><span><img src="@/assets/svg/breadcrumb-separator.svg" alt="arrow icon" class="separator"/>Back to Notifications</span></a>

    <hr/>

    <div class="container dfw">
        <h1>Notification Settings</h1>
    </div>

    <hr/>

    <div class="general-container">
        <div class="block">
            <h2>Digest Notifications</h2>
            <div class="field">
                <input :id="'frequency-active'"
                       class="switch is-rounded"
                       v-on:click="clickActive($event)"
                       type="checkbox"
                       :value="'Frequency Active'"
                       :checked="showFrequencies"
                >
                <label :for="'frequency-active'"
                       class="agency-filter-label">Receive notifications combined in one digest</label>
            </div>

            <div>
                <h6>Frequency</h6>
                <custom-select
                    :uniqueId="uniqueId"
                    :items="frequency"
                    name="frequency"
                    v-model:value="frequency"
                    style="width: 300px"
                ></custom-select>
            </div>
        </div>

        <div class="block">
            <h2>Immediate Notifications</h2>
            <div class="field dark">
                <input :id="'all-immediate'"
                       class="switch is-rounded"
                       v-on:click="clickAllContentType($event)"
                       type="checkbox"
                       :value="'All Immediate'"
                       :checked="allContentTypesSet"
                >
                <label :for="'all-immediate'"
                       class="agency-filter-label">Toggle all immediate notifications</label>
            </div>

            <div class="notification-group">
                <div class="content-block__content">
                    <ul class="box-settings">
                        <li v-for="contentType in contentTypes">
                            <div class="field">
                                <input :id="'contentType-' + contentType.value"
                                       class="switch is-rounded"
                                       v-on:click="clickContentType($event)"
                                       type="checkbox"
                                       :value="contentType.value"
                                       :checked="setContentTypes.indexOf(contentType.value) !== -1"
                                >
                                <label :for="'contentType-' + contentType.value"
                                       class="agency-filter-label">{{ contentType.name }}</label>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="block ">
            <h2>My Keywords</h2>

            <div class="words-notification field">
                <div class="level-left">
                    <div class="level-item">
                        <button class="button button--plus" @click.prevent="showCreate = !showCreate">
                            <img src="@/assets/svg/plus--white.svg" alt="plus icon" v-if="!showCreate"/>
                            <img src="@/assets/svg/minus--white.svg" alt="minus icon" v-else/>
                            {{ showCreate ? 'Hide' : 'Create' }} New Word
                        </button>
                    </div>
                </div>
                    <notification-keywords-create v-if="showCreate" @created="refreshWordList"></notification-keywords-create>

                <hr/>

                <section>
                    <div class="words-list">
                        <div v-for="word in notWords">
                            <div class="notification-words">
                            <span class="word-note">
                                {{word}} <span @click="closeS(word)">×</span>
                            </span>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </div>


        <div class="block">
            <h2>My Notification Items</h2>
            <section>
                <div class="content-block__content">
                    <div class="notification-filter">
                        <span class="filter-label">Filter by:</span>
                        <span @click.prevent="filterByType('contact')" :class="{'active': typeFilter === 'contact'}"
                              title="Filter by Contact"
                        >
                        <tag-contact :tag-text="'Contact'"></tag-contact>
                    </span>
                        <span @click.prevent="filterByType('position')" :class="{'active': typeFilter === 'position'}"
                              title="Filter by Position"
                        >
                        <tag-role :tag-text="'Position'"></tag-role>
                    </span>
                        <span @click.prevent="filterByType('agency')" :class="{'active': typeFilter === 'agency'}"
                              title="Filter by Agency"
                        >
                        <tag-agency :tag-text="'Agency'"></tag-agency>
                    </span>
                        <span @click.prevent="filterByType('budget')" :class="{'active': typeFilter === 'budget'}"
                              title="Filter by Program"
                        >
                        <tag-budget :tag-text="'Program'"></tag-budget>
                    </span>
                        <span @click.prevent="filterByType('vendor')" :class="{'active': typeFilter === 'vendor'}"
                              title="Filter by Vendor"
                        >
                        <tag-vendor :tag-text="'Vendor'"></tag-vendor>
                    </span>
                        <span @click.prevent="filterByType('contract')" :class="{'active': typeFilter === 'contract'}"
                              title="Filter by Contract"
                        >
                        <tag-contract :tag-text="'Contract'"></tag-contract>
                    </span>
                        <span @click.prevent="filterByType('procurement')" :class="{'active': typeFilter === 'procurement'}"
                              title="Filter by Procurement"
                        >
                        <tag-procurement :tag-text="'Procurement'"></tag-procurement>
                    </span>
                    </div>
                </div>
            </section>

            <div class="filter-input field">
                <label :for="'tbl-keyword-' + uniqueId" class="label display-label">Filter by Keyword</label>
                <div class="search-block">
                    <div class="field has-addons">
                        <div class="control">
                            <input type="search" :id="'tbl-keyword-' + uniqueId" v-model="keyword" class="input-search"
                                   placeholder="Filter by keyword"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <preloader v-if="notificationsLoading"></preloader>

            <section>
                <template v-for="notification in notifications">
                    <my-bookmarks-card-contact
                        v-if="notification.type === 'contact' && notification.contact"
                        :bookmark="notification"
                    ></my-bookmarks-card-contact>

                    <my-bookmarks-card-position
                        v-if="notification.type === 'position' && notification.position"
                        :bookmark="notification"
                    ></my-bookmarks-card-position>

                    <my-bookmarks-card-agency
                        v-if="notification.type === 'agency' && notification.agency"
                        :bookmark="notification"
                    ></my-bookmarks-card-agency>

                    <my-bookmarks-card-program
                        v-if="notification.type === 'budget' && notification.budget"
                        :bookmark="notification"
                    ></my-bookmarks-card-program>

                    <my-bookmarks-card-vendor
                        v-if="notification.type === 'vendor' && notification.vendor"
                        :bookmark="notification"
                    ></my-bookmarks-card-vendor>

                    <my-bookmarks-card-contract
                        v-if="notification.type === 'contract' && notification.contract"
                        :bookmark="notification"
                    ></my-bookmarks-card-contract>

                    <my-bookmarks-card-procurement
                        v-if="notification.type === 'procurement' && notification.procurement"
                        :bookmark="notification"
                    ></my-bookmarks-card-procurement>
                </template>

                <p v-if="!notificationsLoading && notifications.length === 0 && typeFilter">
                    Looks like you don't have any notification enabled {{ pluralizeType(typeFilter) }}.
                </p>
                <p v-if="!notificationsLoading && notifications.length === 0 && !typeFilter">
                    Looks like you don't have any notification enabled items.
                </p>
            </section>

            <pagination :current="page" :page-count="pageCount" v-on:paginated="paginate($event)" v-if="pageCount > 1"/>
        </div>

    </div>
</template>

<script>
import UserTools from "./user-tools.vue";
import Breadcrumbs from "./breadcrumbs.vue";
import GeneralContainer from "./general-container.vue";
import TagVendor from "./tag--vendor.vue";
import TagContract from "./tag--contract.vue";
import TagRole from "./tag--role.vue";
import TagProcurement from "./tag--procurement.vue";
import TagBudget from "./tag--budget.vue";
import TagContact from "./tag--contact.vue";
import TagAgency from "./tag--agency.vue";
import Preloader from "./preloader.vue";
import {computed, nextTick, onMounted, ref, watch} from "vue";
import Pagination from "./pagination.vue";
import MyBookmarksCardContact from "./my-bookmarks-card--contact";
import MyBookmarksCardPosition from "./my-bookmarks-card--position";
import MyBookmarksCardAgency from "./my-bookmarks-card--agency";
import MyBookmarksCardProgram from "./my-bookmarks-card--program";
import MyBookmarksCardVendor from "./my-bookmarks-card--vendor";
import MyBookmarksCardContract from "./my-bookmarks-card--contract";
import MyBookmarksCardProcurement from "./my-bookmarks-card--procurement";
import AppButton from "./app-button.vue";
import Datepicker from "vue3-datepicker";
import {faCalendarDay} from "@fortawesome/pro-duotone-svg-icons";
import CustomSelect from "./custom-select.vue";
import RequestHandler from "@/handler/RequestHandler";
import {useRoute} from "vue-router";
import SearchBlock from "./search-block.vue";
import {useStore} from "vuex";
import {v4 as uuidv4} from "uuid";
import NotificationKeywordsCreate from "./notification-keywords-create.vue";

export default {
    components: {
      NotificationKeywordsCreate,
        CustomSelect,
        Datepicker,
        AppButton,
        Preloader,
        TagAgency, TagContact, TagBudget, TagProcurement, TagRole, TagContract, TagVendor,
        Pagination,
        MyBookmarksCardAgency,
        MyBookmarksCardPosition,
        MyBookmarksCardContact,
        MyBookmarksCardProgram,
        MyBookmarksCardVendor,
        MyBookmarksCardContract,
        MyBookmarksCardProcurement,
        GeneralContainer,
        Breadcrumbs,
        UserTools,
        SearchBlock
    },
    methods: {
        faCalendarDay() {
            return faCalendarDay;
        },
        pluralizeType(type) {
            if (type === 'agency') {
                return 'agencies';
            }
            if (type == 'budget') {
                return 'programs';
            }
            return type + 's';
        },
    },
    setup() {
        const notWords =ref([])
        const store = useStore();
        const route = useRoute();
        const notificationsLoading = ref(true);
        const notifications = ref([]);
        const totalNotificationsCount = ref(null);
        const page = ref(1);
        const pageCount = ref(1);
        const itemsPerPage = 8;
        const typeFilter = ref('');
        const query = ref('');

        const setContentTypes = ref([]);
        const allContentTypesSet = ref(false);

        const showFrequencies = ref(false);
        const frequency = ref('');
        const frequencies = [
            {name:'once a month', value: 'month'},
            {name:'once a week', value: 'week'},
            {name:'once a day', value: 'day'},
        ]
        const contentTypes = [
            {name: 'Agencies', value: 'agency'},
            {name: 'Budget Entities', value: 'budget'},
            {name: 'Contacts', value: 'contact'},
            {name: 'Contracts', value: 'contract'},
            {name: 'Positions', value: 'position'},
            {name: 'Procurements', value: 'procurement'},
            {name: 'Vendors', value: 'vendor'},
        ];
        const showCreate = ref(false);

        const loadNotificationEnabledContent = () => {
            notificationsLoading.value = true;
            notifications.value = [];
            RequestHandler.loadNotificationObjects(
                itemsPerPage,
                (page.value - 1) * itemsPerPage,
                typeFilter.value,
                query.value
            )
                .then(data => {
                    notificationsLoading.value = false;
                    totalNotificationsCount.value = data.totalCount || 0;
                    notifications.value = data.list || [];
                    pageCount.value = data.pageCount || 1;
                })
                .catch(error => {
                    console.error(error);
                    notificationsLoading.value = false;
                });
        };

        const loadNotificationSettings = () => {
            RequestHandler.loadNotificationSetting()
                .then(data => {
                    frequency.value = data.SettingRef['emailFrequency'];
                    if (frequency.value) {
                        showFrequencies.value = true;
                    }
                    setContentTypes.value = data.SettingRef['contentTypes'];
                    notWords.value = data.SettingRef['keywords'];
                    allContentTypesSet.value = setContentTypes.value.length === 7;
                })
                .catch(error => {
                    showFrequencies.value = false;
                    console.error(error);
                });
        };

        const updateNotificationSettings = () => {
            RequestHandler.updateNotificationSetting(
                frequency.value,
                setContentTypes.value,
                notWords.value,
                store.getters.csrf
            )
                .then(data => {
                })
                .catch(error => {
                    console.error(error);
                });
        };
        const closeS = (item) => {
            notWords.value = notWords.value.filter((n)=> {return n != item })
        }

        onMounted(() => {
            loadNotificationSettings();
            if (route.query.type) {
                typeFilter.value = route.query.type;
            }
            loadNotificationEnabledContent();
        });

        watch(frequency, (now) => {
            updateNotificationSettings();
        });
        watch(notWords, () => updateNotificationSettings())

        const searchQueryUpdate = (q) => {
            page.value = 1;
            query.value = q;
            loadNotificationEnabledContent();
        };

        const filterByType = (type) => {
            if (type === typeFilter.value) {
                type = '';
            }
            typeFilter.value = type;
            page.value = 1;
            loadNotificationEnabledContent();
        };

        const clickActive = (event) => {
            showFrequencies.value = !!event.target.checked;
            if (!showFrequencies.value) {
                frequency.value = '';
            }
        }

        const clickContentType = (event) => {
            let contentType = event.target.value;
            if (event.target.checked){
                setContentTypes.value.push(contentType);
            } else {
                let index = setContentTypes.value.indexOf(contentType);
                if (index !== -1) {
                    setContentTypes.value.splice(index, 1);
                }
            }
            allContentTypesSet.value = setContentTypes.value.length === 7;

            updateNotificationSettings();
        }

        const clickAllContentType = (event) => {
            allContentTypesSet.value = !!event.target.checked;
            setContentTypes.value = allContentTypesSet.value ? contentTypes.map(contentType => contentType.value) : [];

            updateNotificationSettings();
        }

        const paginate = (pageTo) => {
            page.value = pageTo;
            loadNotificationEnabledContent();
        };

        const refreshWordList = (n) => {
            notWords.value.push(n);
            updateNotificationSettings();
        }

        // Generate a unique ID for each instance
        const uniqueId = computed(() => {
            return uuidv4();
        });

        return {
            notificationsLoading,
            notifications,
            showFrequencies,
            typeFilter,
            frequency,
            frequencies,
            path: [{ title: 'Notification settings' }],
            contentTypes,
            setContentTypes,
            allContentTypesSet,
            query,
            page,
            pageCount,
            paginate,
            filterByType,
            searchQueryUpdate,
            clickActive,
            clickContentType,
            clickAllContentType,
            showCreate,
            notWords,
            refreshWordList,
            closeS,
            uniqueId,
        }
    }
}

</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .input-search {
        border: 2px solid #022c43;
        height: 50px;
        width: 300px;
    }

    .box-settings {
        column-count: 1;
        @media screen and (min-width: 375px) {
            column-count: 2;
        }
        @media screen and (min-width: 900px) {
            column-count: 3;
        }
        @media screen and (min-width: 1216px) {
            column-count: 4;
        }
    }

    .block {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        padding: 15px;
        width: 100%;
        @media screen and (min-width: 769px) {
            width: 70%;
        }
    }
    .row {
        display: flex;
        flex-direction: row;
    }
    .filter-left {
        border: 1px solid $blue--med;
        width: 30%;
        background-color: white;
    }
    .filter-body {
        display: flex;
        flex-direction: column;
    }
    .active-only {
        background-color: rgba(51, 116, 170, 0.3);
        text-align: center;
        margin-bottom: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .general-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .notification-filter {
        margin-bottom: 15px;
        span {
            opacity: 0.7;

            &.active {
                opacity: 1;
            }
        }
    }

    .separator {
        display: inline-block;
        margin-right: 10px;
        transform: rotate(180deg);
        top: 3px;
        position: relative;
    }

    h2 {
        margin-bottom: 15px;
        line-height: 1.5em;
        text-align: center;
    }

    p.freq {
        margin-bottom: 10px;
        line-height: 1.5em;
        text-align: center;
        font-size: 1rem;
        text-align: left;
    }

    .filter-input.field {
        width: 90%;
        @media screen and (min-width: 769px) {
            width: 70%;
        }
        @media screen and (min-width: 1216px) {
            width: 50%;
        }
    }

    .field {
        &.dark {
            width: 100%;
            text-align: center;
            background-color: $blue--dark;
            color: white;
            border-radius: 8px;
            padding: 5px;
        }
        .control {
            width: calc(100% - 70px)!important;
        }
    }

    .words-notification {
        width: 90%;
        @media screen and (min-width: 769px) {
            width: 85%;
        }
        @media screen and (min-width: 1216px) {
            width: 85%;
        }
    }

    .search-block .control:first-of-type:not(.full-width) {
        width: calc(100% - 70px)!important;
    }

    .filter-label {
        margin-right: 10px;
        color: $blue--dark;
        @media screen and (max-width: 1420px) {
            margin-right: 0px;
            width: 100%;
            text-align: center;
            display: block;
            margin-bottom: 10px;
        }
    }

    .field-frequency {
        margin-bottom: 10px;
    }
    .button.button--plus {
        font-weight: bold;
        font-family: $header;

        &:focus, &:active, &:hover {
            color: $white;
        }

        img {
            width: 25px;
            height: 25px;
            margin-right: 5px;
        }
    }
    .notification-words {
        margin:  10px;
    }

    .words-list {
        display: flex;
        flex-wrap: wrap;
    }
    .word-note {
        background: #f6f6f6;
        //margin-right: 10px;
        padding: 5px 10px;
        border-radius: 15px;
        font-weight: 600;
        font-size: 16px;
        &:focus, &:active, &:hover {
            color: $blue--med;
            cursor: pointer;
        }
    }
</style>
